@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/gotham');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #ffffff;
  /* font-family: "Inter", sans-serif; */
  font-family: "gotham";
}

html {
  height: 100%;
}

body {
  height: 100%;
  background-color: #000000;
}

#root {
  height: 100%;
}